$(function () {
  // ヘッダー
  const header = document.getElementById('js-header');
  const trigger = document.getElementById('js-trigger');
  function navToggle() {
    if (header.classList.contains('is-open')) {
      navCloseFunc();
    } else {
      navOpenFunc();
    }
  }

  function navOpenFunc() {
    header.classList.add('is-open');
  }

  function navCloseFunc() {
    header.classList.remove('is-open');
  }

  trigger.addEventListener('click', navToggle);


  // ボタン表示
  const btn = $('#js-btn');
  $(window).scroll(function () {
    if ($(this).scrollTop() > 360) {
      btn.addClass('display');
    } else if ($(this).scrollTop() < 360) {
      btn.removeClass('display');
    }
  });

  // スクロールでふわっと表示
  $(window).scroll(function () {
    $('.js-fade').each(function () {
      let position = $(this).offset().top;
      let scroll = $(window).scrollTop();
      let windowHeight = $(window).height();
      if (scroll > position - windowHeight + 100) {
        $(this).addClass('scroll');
      }
    });
  });

  // スクロールアンカーリンク
  $('.move a[href^="#"]').click(function () {
    let adjust = 60;
    let speed = 600;
    let href = $(this).attr('href');
    let target = $(href == "#" || href == "" ? 'html' : href);
    let position = target.offset().top - adjust;
    $('body, html').animate({ scrollTop: position }, speed, 'swing');
    return false;
  })

  // スクロールトップ
  $('#js-pagetop').click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 600);
  });

  // 選択ボタン押下
  $('.js-select').on('click', function () {
    let poster = $(this).data('poster');
    let addBtn = $(this);
    let removeBtn = $(this).next();

    $.ajax({
      url: '/order/add_poster',
      type: 'POST',
      data: {
        poster: poster
      },
    }).done(function () {
      addBtn.removeClass('active');
      removeBtn.addClass('active');
    });
  });

  // 解除ボタン押下
  $('.js-deselect').on('click', function () {
    let poster = $(this).data('poster');
    let addBtn = $(this).prev();
    let removeBtn = $(this);

    $.ajax({
      url: '/order/remove_poster',
      type: 'POST',
      data: {
        poster: poster
      },
    }).done(function () {
      addBtn.addClass('active');
      removeBtn.removeClass('active');
    });
  });

  // フォーム×ボタン押下
  $('.js-remove').on('click', function () {
    if (confirm('削除してもよろしいですか?')) {
      let poster = $(this).data('poster');
      let removeBtn = $(this);

      $.ajax({
        url: '/order/remove_poster',
        type: 'POST',
        data: {
          poster: poster
        },
      }).done(function () {
        removeBtn.parent().remove();
      });
    } else {
      return false;
    }
  });

  // 住所自動入力
  $('#inquiry_postal1').jpostal({
    click: '#js-address',
    postcode: [
      '#inquiry_postal1',
      '#inquiry_postal2'
    ],
    address: {
      "#inquiry_prefecture": "%3",
      "#inquiry_city": "%4",
      "#inquiry_address": "%5%6%7"
    }
  });

  $('#inquiry_shipping_postal1').jpostal({
    click: '#js-shipping_address',
    postcode: [
      '#inquiry_shipping_postal1',
      '#inquiry_shipping_postal2'
    ],
    address: {
      "#inquiry_shipping_prefecture": "%3",
      "#inquiry_shipping_city": "%4",
      "#inquiry_shipping_address": "%5%6%7"
    }
  });

  // 発送先指定表示
  $('#inquiry_separate_address').on('change', function () {
    let checked = $(this).prop('checked');
    if (checked) {
      $('#separate_address_form').css('display', 'block');
    } else {
      $('#separate_address_form').css('display', 'none');
    }
  });

  // 発送先初期表示
  $(document).ready(function() {
    let separateAddress = $('#inquiry_separate_address').prop('checked');
    if (separateAddress == true) {
      $('#separate_address_form').css('display', 'block');
    }
  })

  // ポスターバリデーション
  $('#js-form').on('submit', function () {
    let count = 0;
    let posterCount = $('.posters_list_item').length;
    let companyName = $('#inquiry_company_name').val();
    let companyKana = $('#inquiry_company_kana').val();
    let chargeName = $('#inquiry_charge_name').val();
    let chargeKana = $('#inquiry_charge_kana').val();
    let tel = $('#inquiry_tel').val();
    let email = $('#inquiry_email').val();
    let postal1 = $('#inquiry_postal1').val();
    let postal2 = $('#inquiry_postal2').val();
    let prefecture = $('#inquiry_prefecture').val();
    let city = $('#inquiry_city').val();
    let address = $('#inquiry_address').val();

    $('.item_error_text').remove();
    $('.posters_list_item_form_error_text').remove();

    $('.posters_list_item').each(function (i, element) {
      let b1Value = $(element).find('.posters_list_item_form_sheet_input[name="inquiry[posters][][b1]"]').val();
      let b2Value = $(element).find('.posters_list_item_form_sheet_input[name="inquiry[posters][][b2]"]').val();
      let b3Value = $(element).find('.posters_list_item_form_sheet_input[name="inquiry[posters][][b3]"]').val();
      if ((b1Value == '' || b1Value == undefined) && (b2Value == '' || b2Value == undefined) && (b3Value == '' || b3Value == undefined)) {
        $(element).find('.posters_list_item_form_error').append('<p class="posters_list_item_form_error_text">枚数をご入力ください</p>');
        count++;
      }
    });
    if (posterCount == 0) {
      $('.item_error').append('<p class="item_error_text">ポスターを1つ以上選択してください</p>');
      count++;
    }
    if (companyName == '') {
      $('.item_error').append('<p class="item_error_text">団体名・事業者名を入力してください</p>');
      count++;
    }
    if (companyKana == '') {
      $('.item_error').append('<p class="item_error_text">団体名・事業者名（ふりがな）を入力してください</p>');
      count++;
    }
    if (chargeName == '') {
      $('.item_error').append('<p class="item_error_text">担当者名を入力してください</p>');
      count++;
    }
    if (chargeKana == '') {
      $('.item_error').append('<p class="item_error_text">担当者名(ふりがな)を入力してください</p>');
      count++;
    }
    if (tel == '') {
      $('.item_error').append('<p class="item_error_text">電話番号を入力してください</p>');
      count++;
    }
    if (email == '') {
      $('.item_error').append('<p class="item_error_text">メールアドレスを入力してください</p>');
      count++;
    }
    if (postal1 == '' || postal2 == '') {
      $('.item_error').append('<p class="item_error_text">郵便番号を入力してください</p>');
      count++;
    }
    if (prefecture == '') {
      $('.item_error').append('<p class="item_error_text">都道府県を入力してください</p>');
      count++;
    }
    if (city == '') {
      $('.item_error').append('<p class="item_error_text">ご住所(市区町村)を入力してください</p>');
      count++;
    }
    if (address == '') {
      $('.item_error').append('<p class="item_error_text">ご住所(丁目番地号)を入力してください</p>');
      count++;
    }

    if ($('#inquiry_separate_address').prop('checked')) {
      let shippingCompanyName = $('#inquiry_shipping_company_name').val();
      let shippingCompanyKana = $('#inquiry_shipping_company_kana').val();
      let shippingChargeName = $('#inquiry_shipping_charge_name').val();
      let shippingChargeKana = $('#inquiry_shipping_charge_kana').val();
      let shippingTel = $('#inquiry_shipping_tel').val();
      let shippingEmail = $('#inquiry_shipping_email').val();
      let shippingPostal1 = $('#inquiry_shipping_postal1').val();
      let shippingPostal2 = $('#inquiry_shipping_postal2').val();
      let shippingPrefecture = $('#inquiry_shipping_prefecture').val();
      let shippingCity = $('#inquiry_shipping_city').val();
      let shippingAddress = $('#inquiry_shipping_address').val();

      if (shippingCompanyName == '') {
        $('.item_error').append('<p class="item_error_text">配送先団体名・事業者名を入力してください</p>');
        count++;
      }
      if (shippingCompanyKana == '') {
        $('.item_error').append('<p class="item_error_text">配送先団体名・事業者名（ふりがな）を入力してください</p>');
        count++;
      }
      if (shippingChargeName == '') {
        $('.item_error').append('<p class="item_error_text">配送先担当者名を入力してください</p>');
        count++;
      }
      if (shippingChargeKana == '') {
        $('.item_error').append('<p class="item_error_text">配送先担当者名(ふりがな)を入力してください</p>');
        count++;
      }
      if (shippingTel == '') {
        $('.item_error').append('<p class="item_error_text">配送先電話番号を入力してください</p>');
        count++;
      }
      if (shippingEmail == '') {
        $('.item_error').append('<p class="item_error_text">配送先メールアドレスを入力してください</p>');
        count++;
      }
      if (shippingPostal1 == '' || shippingPostal2 == '') {
        $('.item_error').append('<p class="item_error_text">配送先郵便番号を入力してください</p>');
        count++;
      }
      if (shippingPrefecture == '') {
        $('.item_error').append('<p class="item_error_text">配送先都道府県を入力してください</p>');
        count++;
      }
      if (shippingCity == '') {
        $('.item_error').append('<p class="item_error_text">配送先ご住所(市区町村)を入力してください</p>');
        count++;
      }
      if (shippingAddress == '') {
        $('.item_error').append('<p class="item_error_text">配送先ご住所(丁目番地号)を入力してください</p>');
        count++;
      }
    }

    if (count != 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return false;
    }
  });
});
